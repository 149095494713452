import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  updateOlsTrackingRequest: ['records'],
  updateOlsTrackingSuccess: ['olsTrackingData'],
  updateOlsTrackingFailure: ['error'],

  resetTrackingData: [''],
});

const Actions = {
  Types,
  Creators,
};

export default Actions;
