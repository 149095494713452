import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  getPlansRequest: ['locationId'],
  getPlansSuccess: ['plans'],
  getPlansFailure: ['error'],

  getMembershipPlanInfoRequest: ['locationName'],
  getMembershipPlanInfoSuccess: ['membershipPlanInfo'],
  getMembershipPlanInfoFailure: ['error'],

  getNewOlsPlansRequest: ['locationId'],
  getNewOlsPlansSuccess: ['newOlsPlans'],
  getNewOlsPlansFailure: ['error'],

  findUserByEmailRequest: ['email'],
  findUserByEmailSuccess: ['userExistenceData'],
  findUserByEmailFailure: ['error'],

  uploadPhotoRequest: ['caId', 'info'],
  uploadPhotoSuccess: ['uploadedPhoto'],
  uploadPhotoFailure: ['error'],

  editNewCardRequest: ['card'],
  editNewCardSuccess: ['existingUserNewCard'],
  editNewCardFailure: ['error'],

  addUserPersonalInfo: ['userPersonalInfo'],

  addCard: ['card'],

  resetArrayState: ['data'],
  resetObjectState: ['data'],

  createNewUserRequest: ['user'],
  createNewUserSuccess: ['newUserInfo'],
  createNewUserFailure: ['error'],

  getPaymentDetailsRequest: ['user'],
  getPaymentDetailsSuccess: ['paymentDetails'],
  getPaymentDetailsFailure: ['error'],

  addExistingUserNewCardRequest: ['card'],
  addExistingUserNewCardSuccess: ['existingUserNewCard'],
  addExistingUserNewCardFailure: ['error'],

  updateExistingUserRequest: ['user'],
  updateExistingUserSuccess: ['updatedUserDetails'],
  updateExistingUserFailure: ['error'],

  editExistingUserCardRequest: ['card'],
  editExistingUserCardSuccess: ['editedCardInfo'],
  editExistingUserCardFailure: ['error'],

  getPlanInfoRequest: ['info'],
  getPlanInfoSuccess: ['user', 'planInfo'],
  getPlanInfoFailure: ['error'],

  createGuestRequest: ['info'],
  createGuestSuccess: ['user'],
  createGuestFailure: ['error'],

  addUserDetailsRequest: ['user'],
  addUserDetailsSuccess: ['user'],
  addUserDetailsFailure: ['error'],

  checkReferralCodeRequest: ['code', 'isReferral'],
  checkReferralCodeSuccess: ['person'],
  checkReferralCodeFailure: ['error'],

  createUserRequest: ['info'],
  createUserSuccess: ['user'],
  createUserFailure: ['error'],

  checkPromoCodeSuccess: ['data'],

  updateUtmInfoRequest: ['payload'],
  updateUtmInfoSuccess: ['utmInfoDetails'],
  updateUtmInfoFailure: ['error'],

  getSubmitFailureDataRequest: [''],
  getSubmitFailureDataSuccess: ['failureData'],
  getSubmitFailureDataFailure: ['error'],

  createUserRetryRequest: ['payload'],
  createUserRetrySuccess: ['retryUserData'],
  createUserRetryFailure: ['error'],
});

const Actions = {
  Types,
  Creators,
};

export default Actions;
