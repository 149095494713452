import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from './style.module.css';
import { arrow_down, arrow_up, olsLocation } from 'assets/icons';
import { CircularProgress } from '@mui/material';
import BackButton from 'layouts/RegistrationLayoutNew/Buttons/BackButton';

const SelectPlanSection = ({
  selectedClub,
  newOlsPlans,
  selectedPlan,
  setSelectedPlan,
  onNext,
  onBack,
  isMobile,
  isSmallMobile,
  membershipPlanInfo,
  resetArrayState,
  setSelectedPlanName,
  updateOlsTracking,
  trackingId,
  emailAddress,
  title,
  referralPerson,
  userExist,
}) => {
  const [isShowPlans, setShowPlans] = useState(false);
  const [selectedIndices, setSelectedIndices] = useState([]);
  const history = useHistory();
  const oaklandPlanDescription = [
    'Access to Oakland',
    'Full Weight Floor & Cardio Deck',
    'Group Fitness Classes',
    'Full-Service Locker Rooms',
    'Towel Service',
    'Complimentary FIT Session',
  ];
  const marinPlanDescription = [
    'Access to Marin',
    'Full Weight Floor & Cardio Deck',
    'HydroMassage Beds & Massage Chairs',
    'Outdoor Gym',
    'Full-Service Locker Rooms',
    'Towel Service',
    'Free Parking',
    'Complimentary FIT Session',
  ];
  const allGymPlanDescription = [
    'Access to All Gyms',
    'Full Weight Floor & Cardio Deck',
    'Group Fitness Classes',
    'HydroMassage Beds & Massage Chairs',
    'Full-Service Locker Rooms',
    'Towel Service',
    'Pool, Sauna & Hot Tub <i>(Fillmore)</i>',
    'Free Parking <i>(Select Locations)</i>',
    'Complimentary FIT Session',
  ];
  const handleBack = () => {
    resetArrayState('newOlsPlans');
    setShowPlans(false);
    onBack();
    history.push('/');
  };

  const handleTrackingApi = planName => {
    const data = {
      session: trackingId,
      email: emailAddress,
      currentPage: title,
      data: JSON.stringify({ membership: planName }),
      isFormerMember: userExist,
      isReferral: Object.keys(referralPerson).length !== 0,
      referrerName: Object.keys(referralPerson).length !== 0 ? referralPerson.name : null,
      referrerCaid:
        Object.keys(referralPerson).length !== 0 ? referralPerson?.caID : null,
    };
    updateOlsTracking(data);
  };

  const handleToggle = index => {
    if (selectedIndices.includes(index)) {
      setSelectedIndices(selectedIndices.filter(i => i !== index));
    } else {
      setSelectedIndices([...selectedIndices, index]);
    }
  };

  const handleSelectCard = (club, index) => {
    setSelectedPlan(club.id);
    setSelectedPlanName(club.selectedPlanName);
    // if (isMobile) {
    //   handleToggle(index);
    // }
  };

  const clubPlans = (Object.keys(membershipPlanInfo).length !== 0
    ? membershipPlanInfo.data.planData
    : newOlsPlans
  )
    .map(({ id, name, price, description }) => {
      const type = name && name.toLowerCase().includes('month') ? 'monthly' : 'annually';
      const planName = name && name.replace(/\([^)]+\)/, '').split('-')[1];
      const formattedPrice = `$${price}`;
      if (planName.includes('Oakland')) {
        description = oaklandPlanDescription;
      } else if (planName.includes('Marin')) {
        description = marinPlanDescription;
      } else {
        description = allGymPlanDescription;
      }
      return {
        id,
        type,
        name: planName,
        price: formattedPrice,
        description,
        selectedPlanName: name,
      };
    })
    .sort((a, b) => {
      const priceA = parseFloat(a.price.slice(1));
      const priceB = parseFloat(b.price.slice(1));
      return priceA - priceB;
    });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const currentPlans = (Object.keys(membershipPlanInfo).length !== 0
      ? membershipPlanInfo.data.planData
      : newOlsPlans
    ).filter(el =>
      el.locationIds.some(
        i =>
          parseInt(i) ===
          (Object.keys(membershipPlanInfo).length !== 0
            ? parseInt(membershipPlanInfo.data.clubId)
            : +selectedClub?.clubautomationclubidC)
      )
    );

    if (currentPlans?.length > 0) {
      setShowPlans(true);
    } else {
      setShowPlans(false);
    }
  }, [selectedClub?.clubautomationclubidC, newOlsPlans, membershipPlanInfo]);

  return isShowPlans === true ? (
    <>
      <div className={`${styles.locationContainer} ${isMobile ? styles.mobile : ''}`}>
        <div
          onClick={() => handleBack()}
          style={{
            display: 'flex',
            cursor: 'pointer',
          }}
        >
          <img
            src={olsLocation}
            className={`${styles.locationIcon} ${isMobile ? styles.mobile : ''}`}
            alt=""
          />
          <p className={`${styles.locationText} ${isMobile ? styles.mobile : ''}`}>
            {selectedClub?.displayName}
          </p>
        </div>
      </div>
      <div className={`${styles.body} ${isMobile ? styles.mobile : ''}`}>
        {clubPlans.map((c, index) => (
          <>
            <div
              className={`${styles.wrapper} ${selectedPlan === c.id}`}
              key={c.id}
              onClick={() => handleSelectCard(c, index)}
            >
              <div
                value={selectedPlan === c.id}
                className={`
                ${styles.card} ${isMobile ? styles.mobile : ''}${
                  selectedIndices.includes(index) ? styles.expand : ''
                } ${isSmallMobile ? styles.smallMob : ''}

                `}
              >
                <h1 className={`${styles.planName}`}>{c.name}</h1>
                <div className={styles.innerWrapper}>
                  <div className={styles.priceWrappper}>
                    <p className={isMobile ? styles.planPriceMobile : styles.planPrice}>
                      {c.price}
                    </p>
                    <p className={styles.planPriceDescription}>
                      Monthly recurring payment
                    </p>
                  </div>
                  <div className={styles.SelectBtnWrapper}>
                    <button
                      onClick={() => {
                        onNext();
                        if (trackingId) {
                          handleTrackingApi(c.selectedPlanName);
                        }
                      }}
                      className={styles.selectBtn}
                    >
                      Select
                    </button>
                  </div>
                  <div className={`${styles.descriptionWrapper}`}>
                    <h1 className={styles.descriptionHeading}>
                      {c.name.includes('Premier') ? (
                        <>Includes All Gym Access, plus:</>
                      ) : (
                        <>Includes :</>
                      )}
                    </h1>
                    <p className={styles.descriptionPart1}>
                      {c.description.slice(0, 3).map(e => {
                        return (
                          <>
                            {e} <br />
                          </>
                        );
                      })}
                    </p>
                    <div
                      className={
                        isMobile && !selectedIndices.includes(index)
                          ? styles.toggleArrowDiv
                          : ''
                      }
                    >
                      {isMobile && !selectedIndices.includes(index) ? (
                        <div key={index} onClick={() => handleToggle(index)}>
                          <p>View More</p>
                          <img src={arrow_down} className={styles.toggleArrow} alt="" />
                        </div>
                      ) : null}
                    </div>
                    <p
                      className={`${styles.descriptionPart2} ${
                        isMobile ? styles.mobile : ''
                      } ${
                        isMobile && selectedIndices.includes(index) ? styles.show : ''
                      }`}
                    >
                      {c.description.slice(3, c.description.length).map(e => {
                        return (
                          <>
                            <span dangerouslySetInnerHTML={{ __html: e }}></span>
                            <br />
                          </>
                        );
                      })}
                      <p className={styles.descriptionSubHeading}>Digital Features:</p>
                      <p>
                        Nutrition{' '}
                        <span
                          dangerouslySetInnerHTML={{ __html: '<i>(by EatLove)</i>' }}
                        ></span>
                        <br />
                        Mental Wellness
                        <span
                          dangerouslySetInnerHTML={{ __html: '<i>(by Zama)</i>' }}
                        ></span>
                      </p>
                    </p>
                    <div
                      className={
                        isMobile && selectedIndices.includes(index)
                          ? styles.toggleArrowDiv
                          : ''
                      }
                    >
                      {isMobile && selectedIndices.includes(index) ? (
                        <div key={index} onClick={() => handleToggle(index)}>
                          <p>View Less</p>
                          <img src={arrow_up} className={styles.toggleArrow} alt="" />
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ))}
      </div>
      <BackButton
        onBack={() => handleBack()}
        backButtonName="Choose a different location"
      />
    </>
  ) : (
    <div className={styles.progressbarDiv}>
      <CircularProgress
        className={styles.circularProgress}
        style={{ color: '#fe5000' }}
      />
    </div>
  );
};

SelectPlanSection.propTypes = {
  selectedClub: PropTypes.string.isRequired,
  newOlsPlans: PropTypes.array.isRequired,
  selectedPlan: PropTypes.string.isRequired,
  setSelectedPlan: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
};

SelectPlanSection.defaultValue = {
  newOlsPlans: [],
};

export default SelectPlanSection;
