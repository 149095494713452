import { call, put, takeLeading } from 'redux-saga/effects';
import actions from './actions';
import errorActions from '../error/actions';
import dxApi from 'services/dxApi';

const {
  Types: { UPDATE_OLS_TRACKING_REQUEST },
  Creators,
} = actions;

const {
  Creators: { setError },
} = errorActions;

function* updateOlsTracking({ records }) {
  try {
    const { data } = yield call(dxApi.post, `/dashboard/set-ols-tracking`, records);
    if (data.success === false) {
      yield put(Creators.updateOlsTrackingFailure(data.message));
      yield put(setError(data.message));
    } else {
      yield put(Creators.updateOlsTrackingSuccess(data));
    }
  } catch (err) {
    console.log('error in ols tracking Api', err);
    yield put(Creators.updateOlsTrackingFailure(err));
  }
}

export function* olsTrackingSaga() {
  yield takeLeading(UPDATE_OLS_TRACKING_REQUEST, updateOlsTracking);
}
