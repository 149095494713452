import { createReducer } from 'reduxsauce';
import actions from './actions';

const { Types } = actions;
const INITIAL_STATE = {
  isOlsTrackingLoading: false,
  olsTrackingData: {},
  trackingSessionID: '',
  error: null,
};

const updateOlsTrackingRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isOlsTrackingLoading: true,
    error: null,
  };
};

const updateOlsTrackingSuccess = (state = INITIAL_STATE, { olsTrackingData }) => {
  return {
    ...state,
    olsTrackingData,
    trackingSessionID: olsTrackingData.data || state.trackingSessionID,
    isOlsTrackingLoading: false,
  };
};

const updateOlsTrackingFailure = (state = INITIAL_STATE, { error }) => {
  return {
    ...state,
    isOlsTrackingLoading: false,
    error,
  };
};

const resetTrackingData = (state = INITIAL_STATE, {}) => {
  return {
    ...state,
    olsTrackingData: {},
    isOlsTrackingLoading: false,
    trackingSessionID: '',
  };
};

export const HANDLERS = {
  [Types.UPDATE_OLS_TRACKING_REQUEST]: updateOlsTrackingRequest,
  [Types.UPDATE_OLS_TRACKING_SUCCESS]: updateOlsTrackingSuccess,
  [Types.UPDATE_OLS_TRACKING_FAILURE]: updateOlsTrackingFailure,

  [Types.RESET_TRACKING_DATA]: resetTrackingData,
};

export default createReducer(INITIAL_STATE, HANDLERS);
