import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import auth from './auth/reducers';
import clubs from './clubs/reducers';
import checkHistory from './history/reducers';
import billing from './billing/reducers';
import history from './history';
import error from './error/reducers';
import messages from './messages/reducers';
import sessionCall from './sessionCall/reducers';
import signUp from './signUp/reducers';
import newOls from './newOls/reducers';
import chatbot from './chatbot/reducers';
import tracking from './tracking/reducers';

const reducers = {
  router: connectRouter(history),
  auth,
  clubs,
  checkHistory,
  billing,
  error,
  messages,
  sessionCall,
  signUp,
  newOls,
  chatbot,
  tracking,
};

export default combineReducers(reducers);
