import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.css';
import { emailAddressRegex } from 'validation/auth.validation';
import Textfield from 'components/shared/Textfield';
import BackAndNextButton from 'layouts/RegistrationLayoutNew/Buttons/BackAndNextButton';
import { CircularProgress, Box, Modal } from '@material-ui/core';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  height: 250,
  bgcolor: 'background.paper',
  boxShadow: 24,
  display: 'flex',
  justifyContent: 'center',
  borderRadius: 20,
};

const EmailAddressInfo = ({
  emailAddress,
  setemailAddress,
  findUserByEmail,
  onNext,
  onBack,
  isMobile,
  isSmallMobile,
  userExistenceData,
  isRequestingEmail,
  referralPerson,
  updateOlsTracking,
  trackingId,
  title,
  userExist,
  emailChanged,
}) => {
  const addressInputWidth = isMobile ? '80vw' : isSmallMobile ? '89.4vw' : '55vw';
  const [invalid, setinvalid] = useState(false);
  const [errorMessage, seterrorMessage] = useState('');
  const [shouldRunEffect, setShouldRunEffect] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  function setEmailToLocal(newEmail) {
    const email = localStorage.getItem('email');
    if (email !== newEmail) {
      if (email) {
        emailChanged();
      }
      localStorage.setItem('email', newEmail);
    }
  }

  const handleTrackingApi = emailAddress => {
    const data = {
      session: trackingId,
      email: emailAddress,
      currentPage: title,
      data: JSON.stringify({ email: emailAddress }),
      isFormerMember: userExist,
      isReferral: Object.keys(referralPerson).length !== 0,
      referrerName: Object.keys(referralPerson).length !== 0 ? referralPerson.name : null,
      referrerCaid:
        Object.keys(referralPerson).length !== 0 ? referralPerson?.caID : null,
    };
    updateOlsTracking(data);
  };

  const handleEmailValidation = () => {
    if (!emailAddressRegex.test(emailAddress)) {
      setinvalid(true);
      seterrorMessage('Invalid email address');
      return;
    }
    if (trackingId) {
      handleTrackingApi(emailAddress);
    }
    setEmailToLocal(emailAddress);
    findUserByEmail(emailAddress);
    setShouldRunEffect(true);
  };

  useEffect(() => {
    if (userExistenceData === undefined) {
      setShouldRunEffect(false);
      // if (Object.keys(referralPerson).length !== 0) {
      //   handleOpen();
      // }
    }
  }, [userExistenceData]);

  useEffect(() => {
    if (shouldRunEffect) {
      if (userExistenceData !== undefined) {
        onNext();
        setShouldRunEffect(false);
      }
    }
  }, [shouldRunEffect, userExistenceData]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className={`${styles.box} ${isMobile ? styles.mob : ''}`}>
        {isMobile ? <p className={styles.label}>Email</p> : null}

        <Textfield
          boxShadow={'0px 4px 12px rgba(0, 0, 0, 0.08)'}
          onChange={e => setemailAddress(e.target.value)}
          invalid={invalid}
          id="email"
          error={errorMessage}
          value={emailAddress}
          width={addressInputWidth}
          className={` ${styles.input} ${isMobile ? styles.mob : ''} ${
            isSmallMobile ? styles.smallMob : ''
          }`}
        />
      </div>
      <BackAndNextButton
        backButtonName="Back"
        nextButtonName={
          isRequestingEmail ? (
            <div className={styles.loaderWrapper}>
              <CircularProgress className={styles.loader} size={20} color="inherit" />
              Next
            </div>
          ) : (
            'Next'
          )
        }
        nextButtonDisable={
          emailAddress === null || emailAddress === '' || isRequestingEmail
        }
        onBack={onBack}
        onNext={() => handleEmailValidation()}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <p className={styles.popupMsg}>
            Sorry <br /> you are not eligible <br />
            for this program!
          </p>
        </Box>
      </Modal>
    </div>
  );
};

EmailAddressInfo.propTypes = {
  onBack: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  emailAddress: PropTypes.string.isRequired,
};

export default EmailAddressInfo;
