import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { newRegistrationInfoSchema } from 'validation/auth.validation';
import { initialValues, stateOptions, genderOptions } from './service';
import Field from 'components/shared/Field';
import SelectState from 'components/shared/SelectState';
import SelectGender from 'components/shared/SelectGender';
import MaskInput from 'components/shared/MaskInput';
import Textfield from 'components/shared/Textfield';
import styles from './style.module.css';
import AgeValidationPopup from '../AgeValidationPopup';
import moment from 'moment';
import BackAndNextButton from 'layouts/RegistrationLayoutNew/Buttons/BackAndNextButton';

const PersonalInfo = ({
  userPersonalInfo,
  addUserPersonalInfo,
  isMobile,
  onNext,
  onBack,
  handleEditDetailsOnNext,
  editDetailsOnNext,
  state,
  setState,
  resetObjectState,
  updateOlsTracking,
  trackingId,
  emailAddress,
  title,
  userExist,
  referralPerson,
}) => {
  const inputWidth = isMobile ? '89.41vw' : '29.22vw';
  const smallInputWidth = isMobile ? '43.80vw' : '14.22vw';
  const addressInputWidth = isMobile ? '89.41vw' : '58.98vw';
  const fontStyle = 'Montserrat-light';
  const boxShadow = '0px 4px 12px rgba(0, 0, 0, 0.08)';
  const [openModal, setopenModal] = useState(false);
  const handleClosePopUp = () => {
    setopenModal(false);
  };

  function validateDateOfBirth(birthdate) {
    const currentDate = moment();
    const birthDate = moment(birthdate, 'MM/DD/YYYY', true);

    const age = currentDate.diff(birthDate, 'years');
    if (age >= 18) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleAddState = e => {
    setState(e);
  };

  const handleAddGender = e => {
    setState(e);
  };

  const handleBack = () => {
    resetObjectState('userExistenceData');
    onBack();
  };

  const handleTrackingApi = item => {
    const dataValues = {
      firstName: item.firstName,
      lastName: item.lastName,
      gender: item.gender,
      birthday: item.birthday,
      cellphone: item.phone,
      contactAddressLine1: item.address,
      contactCity: item.city,
      contactState: item.state,
      contactZip: item.zipCode,
      emergencyContactName: item.emergencyContactName,
      emergencyContactPhone: item.emergencyContactPhone,
    };
    const data = {
      session: trackingId,
      email: emailAddress,
      currentPage: title,
      data: JSON.stringify({ ...dataValues }),
      isFormerMember: userExist,
      isReferral: Object.keys(referralPerson).length !== 0,
      referrerName: Object.keys(referralPerson).length !== 0 ? referralPerson.name : null,
      referrerCaid:
        Object.keys(referralPerson).length !== 0 ? referralPerson?.caID : null,
    };
    updateOlsTracking(data);
  };

  return (
    <>
      <AgeValidationPopup open={openModal} onClose={handleClosePopUp} />
      <Formik
        initialValues={initialValues(userPersonalInfo)}
        onSubmit={values => {
          if (validateDateOfBirth(values.birthday) === false) {
            setopenModal(true);
          } else {
            handleTrackingApi({ ...values });
            addUserPersonalInfo({ ...values });
            if (editDetailsOnNext === 3) {
              handleEditDetailsOnNext();
            } else {
              onNext();
            }
          }
        }}
        validationSchema={newRegistrationInfoSchema}
      >
        {({ handleSubmit, handleChange, values, errors, touched }) => {
          return (
            <div>
              <div className={`${styles.form1} ${isMobile ? styles.mobile : ''}`}>
                <div
                  className={`${styles.inputWrapper}  ${isMobile ? styles.mobile : ''}`}
                >
                  <div className={`${styles.wrapper} ${isMobile ? styles.mobile : ''}`}>
                    <p className={styles.label}>
                      First Name
                      <Field
                        autoComplete="off"
                        value={values.firstName}
                        onChange={handleChange}
                        name="firstName"
                        width={inputWidth}
                        className={styles.input}
                        invalid={touched.firstName && Boolean(errors.firstName)}
                        error={errors.firstName}
                        boxShadow={boxShadow}
                        fontFamily={fontStyle}
                      />
                    </p>
                    <p className={styles.label}>
                      Last Name
                      <Field
                        value={values.lastName}
                        onChange={handleChange}
                        name="lastName"
                        width={inputWidth}
                        className={styles.input}
                        invalid={touched.lastName && Boolean(errors.lastName)}
                        error={errors.lastName}
                        boxShadow={boxShadow}
                        fontFamily={fontStyle}
                      />
                    </p>
                  </div>
                  <div className={`${styles.wrapper} ${isMobile ? styles.mobile : ''}`}>
                    <div
                      className={`${styles.innerContainer} ${
                        isMobile ? styles.mobile : ''
                      }`}
                    >
                      <p className={styles.stateLabel}>
                        Gender
                        <SelectGender
                          height={'50px'}
                          backgroundColor={'white'}
                          borderRadius={'10px'}
                          marginTop={'5px'}
                          placeholder="Select one"
                          name="gender"
                          id="gender"
                          className={styles.input}
                          width={smallInputWidth}
                          hideIndicatorSeparator
                          options={genderOptions}
                          onChange={e => {
                            handleChange('gender')(e.value);
                            handleAddGender(e.label);
                          }}
                          error={errors.gender}
                          invalid={touched.gender && Boolean(errors.gender)}
                          value={values.gender}
                          boxShadow={boxShadow}
                        />
                      </p>
                      <p className={styles.label}>
                        Birthday
                        <MaskInput
                          value={values.birthday}
                          onChange={handleChange}
                          name="birthday"
                          className={styles.input}
                          width={smallInputWidth}
                          placeholder="mm/dd/yyyy"
                          invalid={touched.birthday && Boolean(errors.birthday)}
                          error={errors.birthday}
                          mask="99/99/9999"
                          maskChar={null}
                          boxShadow={boxShadow}
                          fontFamily={fontStyle}
                        />
                      </p>
                    </div>
                    <p className={styles.label}>
                      Phone Number
                      <MaskInput
                        value={values.phone}
                        onChange={handleChange}
                        name="phone"
                        id="phone"
                        width={inputWidth}
                        className={styles.input}
                        invalid={touched.phone && Boolean(errors.phone)}
                        error={errors.phone}
                        mask="999-999-9999"
                        boxShadow={boxShadow}
                        fontFamily={fontStyle}
                      />
                    </p>
                  </div>
                  <div
                    className={`${styles.adressWrapper} ${isMobile ? styles.mobile : ''}`}
                  >
                    <p className={styles.label}>
                      Address
                      <Textfield
                        value={values.address}
                        onChange={handleChange}
                        name="address"
                        width={addressInputWidth}
                        className={styles.addressInput}
                        invalid={touched.address && Boolean(errors.address)}
                        error={errors.address}
                        boxShadow={boxShadow}
                      />
                    </p>
                  </div>
                  <div
                    className={`${styles.adressWrapper} ${isMobile ? styles.mobile : ''}`}
                  >
                    <p className={styles.label}>
                      City
                      <Textfield
                        value={values.city}
                        onChange={handleChange}
                        name="city"
                        id="city"
                        width={addressInputWidth}
                        className={styles.addressInput}
                        invalid={touched.city && Boolean(errors.city)}
                        error={errors.city}
                        boxShadow={boxShadow}
                      />
                    </p>
                  </div>
                  <div className={`${styles.wrapper} ${isMobile ? styles.mobile : ''}`}>
                    <p className={styles.stateLabel}>
                      State
                      <SelectState
                        height={'50px'}
                        backgroundColor={'white'}
                        borderRadius={'10px'}
                        marginTop={'5px'}
                        placeholder="Select a State"
                        name="state"
                        className={styles.input}
                        width={inputWidth}
                        hideIndicatorSeparator
                        options={stateOptions}
                        onChange={e => {
                          handleChange('state')(e.value);
                          handleAddState(e.label);
                        }}
                        error={errors.state}
                        invalid={touched.state && Boolean(errors.state)}
                        value={values.state}
                        boxShadow={boxShadow}
                      />
                    </p>

                    <p className={styles.label}>
                      Zip Code
                      <Field
                        type="text"
                        value={values.zipCode}
                        onChange={handleChange}
                        name="zipCode"
                        width={inputWidth}
                        className={styles.input}
                        invalid={touched.zipCode && Boolean(errors.zipCode)}
                        error={errors.zipCode}
                        min="0"
                        boxShadow={boxShadow}
                        fontFamily={fontStyle}
                      />
                    </p>
                  </div>

                  <div className={`${styles.wrapper} ${isMobile ? styles.mobile : ''}`}>
                    <p className={styles.label}>
                      Emergency Contact Full Name
                      <Field
                        value={values.emergencyContactName}
                        onChange={handleChange}
                        name="emergencyContactName"
                        width={inputWidth}
                        className={styles.input}
                        invalid={
                          touched.emergencyContactName &&
                          Boolean(errors.emergencyContactName)
                        }
                        error={errors.emergencyContactName}
                        boxShadow={boxShadow}
                        fontFamily={fontStyle}
                      />
                    </p>
                    <p className={styles.label}>
                      Emergency Contact Phone Number
                      <MaskInput
                        value={values.emergencyContactPhone}
                        onChange={handleChange}
                        name="emergencyContactPhone"
                        width={inputWidth}
                        className={styles.input}
                        invalid={
                          touched.emergencyContactPhone &&
                          Boolean(errors.emergencyContactPhone)
                        }
                        error={errors.emergencyContactPhone}
                        mask="999-999-9999"
                        boxShadow={boxShadow}
                        fontFamily={fontStyle}
                      />
                    </p>
                  </div>
                  <div className={styles.scroll}></div>
                </div>
                <BackAndNextButton
                  backButtonName="Back"
                  nextButtonName="Next"
                  onBack={() => handleBack()}
                  onNext={handleSubmit}
                />
              </div>
            </div>
          );
        }}
      </Formik>
    </>
  );
};

PersonalInfo.propTypes = {
  referralPerson: PropTypes.object,
  isRequesting: PropTypes.bool.isRequired,
  addUserDetails: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
};

PersonalInfo.defaultProps = {
  referralPerson: null,
};

export default PersonalInfo;
