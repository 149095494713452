import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Header from './Header';
import Menu from './Menu';
import styles from './style.module.css';
import MobileContext from 'context/MobileContext';
import BuddyReferralHeader from './BuddyReferralHeader';

const RegistrationLayoutNew = ({
  tab,
  setTab,
  title,
  children,
  section,
  setSection,
  setTitle,
  titles,
  isLocationValid,
  resetArrayState,
  referralPerson,
  resetObjectState,
}) => {
  const isMobile = useContext(MobileContext);
  return (
    <div className={styles.page}>
      <div className={styles.layout}>
        <div className={styles.menu}>
          <Header
            isLocationValid={isLocationValid}
            isHideMenu={title === 'Congratulation!'}
          />
          {Object.keys(referralPerson).length !== 0 && title !== 'Congratulation!' ? (
            <BuddyReferralHeader referralPerson={referralPerson} />
          ) : null}

          {!isMobile && title !== 'Congratulation!' && (
            <Menu
              setTitle={setTitle}
              titles={titles}
              section={section}
              setSection={setSection}
              setTab={setTab}
              tab={tab}
              isLocationValid={isLocationValid}
              resetArrayState={resetArrayState}
              resetObjectState={resetObjectState}
            />
          )}
        </div>

        <div className={styles.wrapper}>
          <div className={styles.formWrapper}>
            <div>{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

RegistrationLayoutNew.propTypes = {
  title: PropTypes.string.isRequired,
  tab: PropTypes.number.isRequired,
  children: PropTypes.element.isRequired,
};

export default RegistrationLayoutNew;
